import React from 'react';
import { createRoot } from 'react-dom/client';
import DevexHighOrderComponent from '../../src/components/react/devex_high_order_component/devex_high_order_component';
import Events from '../../src/home_page/components/events';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('home-page-events');

  if (!container) return null;
  const root = createRoot(container);

  root.render(
    <DevexHighOrderComponent>
      <Events
        showUpcomingEvents={JSON.parse(container.dataset.showUpcomingEvents)}
        showPastEvents={JSON.parse(container.dataset.showPastEvents)}
      />
    </DevexHighOrderComponent>
  );
});
